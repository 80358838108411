import React from 'react';
import { Card } from 'react-bootstrap';
import './PageResearchStudies.css';
import { strings } from '../../shared';

export const ResearchStudiesCard = ({ generalToken, url }) => {
  return (
    <Card className="research-studies-card">
      <h5 className="mx-3 mt-3 font-weight-bold">
        {strings.research_studies_card.title}
      </h5>
      <Card.Body>
        <span style={{ whiteSpace: 'pre-line' }}>
          {strings.research_studies_card.body}
        </span>
      </Card.Body>
      <div className="flex justify-content-center">
        <button
          className="btn btn-primary mx-3 mt-2 mb-3 text-left "
          style={{ borderTopLeftRadius: '0px', borderTopRightRadius: '0px' }}
          onClick={() =>
            window.open(
              `${url}${generalToken}`,
              '_blank',
              'noopener,noreferrer'
            )
          }
        >
          {strings.research_studies_card.call_to_action}
        </button>
      </div>
    </Card>
  );
};
