export const researchStudiesMap = {
  // PL: [
  //   {
  //     name: 'Manchester Studies',
  //     link: 'https://ir96l7a6vsf.typeform.com/to/RoA2fDzU#wtm_key=',
  //   },
  // ],
  GB: [
    {
      name: 'Manchester Studies',
      link: 'https://ir96l7a6vsf.typeform.com/to/CZcIdHbJ#wtm_key=',
    },
  ],
};
