import React from 'react';
import { Debug } from './Debug';
import { Footer } from './partials/Footer';
import { Loading } from '../Loading';
import { SectionCountryBreakDowns } from './sections/SectionCountryBreakdowns';
import { SectionNearYou } from './sections/SectionNearYou.js';
import SectionPartySummary from './sections/SectionPartySummary';
import { SectionPersonalStats } from './sections/SectionPersonalStats.js';
import { SectionWhyAreYouTargeted } from './sections/SectionWhyAreYouTargeted';
import { strings } from '../../shared';

import './PageResults.css';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min.js';
import { Col, Container, Row } from 'react-bootstrap';
import { SectionAdsTable } from './sections/SectionAdsTable/index.js';
import { SectionPartyTargetingYou } from './sections/SectionPartyTargetingYou.js';
import { SharingSection } from './SharingSection.js';
import { withResultsContext } from '../../shared';
import { handleUserCredentials } from '../../shared';

class PageResults extends React.Component {
  constructor() {
    super();
    this.state = {
      userData: null,
      party: null,
      language: null,
      errorLoading: false,
    };
  }

  refreshUserData = async () => {
    const wtmdata = JSON.parse(localStorage.getItem('wtmdata')) || {};

    if (
      Object.entries(wtmdata).length === 0 &&
      localStorage.getItem('registration_machine_state')
    ) {
      return this.props.history.push('/registration/language');
    }

    try {
      const {
        userData,
        language,
        general_token,
      } = await handleUserCredentials();
      this.setState({
        userData,
        language,
        general_token,

        errorLoading: false,
      });
    } catch (error) {
      console.log(error);
      this.setState({
        errorLoading: true,
      });
      throw new Error(strings.errors.something_wrong_error);
    }
  };

  async componentDidMount() {
    await this.refreshUserData();

    if (!this.state.userData) {
      return;
    }
  }

  hasUserData = () => {
    return !!this.state.userData;
  };

  render() {
    if (!this.hasUserData()) {
      return <Loading errorLoading={this.state.errorLoading} />;
    }

    return (
      <div>
        <Container>
          <Row>
            <Col xs={12}>
              <SectionPersonalStats />
              <SectionWhyAreYouTargeted />
              <SectionPartyTargetingYou />
              <SectionNearYou />
              <SectionCountryBreakDowns />
              <SectionAdsTable />
              <SectionPartySummary />
              <SharingSection />

              <section style={{ textAlign: 'center' }}>
                <Debug general_token={this.state.general_token} />
              </section>
            </Col>
          </Row>
        </Container>

        <Footer />
      </div>
    );
  }
}

export default withResultsContext(withRouter(PageResults));
