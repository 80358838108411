import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import {
  useHistory,
  useLocation,
} from 'react-router-dom/cjs/react-router-dom.min';
import { ResearchStudiesCard } from './ResearchStudiesCard';
import { wtmdata, strings, researchStudiesMap } from '../../shared';

export const PageResearchStudies = ({ transitionToNextState, state }) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const { country } = useParams();
  const [generalToken] = useState(
    JSON.parse(localStorage.getItem('general_token')) || ''
  );
  const userCountry = country || state.event.values.country;
  const researchStudies = researchStudiesMap[userCountry.toUpperCase()];

  // Redirect the user to '/' if the user:
  // 1- has no general_token set OR
  // 2- claims to be from a country which the user has not registered under
  if (
    generalToken?.length === 0 ||
    (wtmdata?.userData?.country !== userCountry.toUpperCase() &&
      !pathname.includes('/registration'))
  ) {
    history.push('/');
    return null;
  }

  return (
    <Container>
      <Row>
        <Col xs={12} md={10} className="my-4">
          <h2 className="my-4">{strings.campaigns.research_studies}</h2>
          <div className="mt-3 mb-4">
            {strings.campaigns.research_studies_description}
          </div>
          <div className="mb-4 mt-3">
            {researchStudies.map((study) => (
              <ResearchStudiesCard
                key={study.link}
                study={study}
                url={study.link}
                generalToken={generalToken}
              />
            ))}
          </div>
          {/* use bootstrap to flex content to the end / right side */}
          <div className="d-flex justify-content-end ">
            <button
              type="submit"
              className="btn text-primary"
              onClick={() =>
                transitionToNextState
                  ? transitionToNextState(state.event.values)
                  : history.push('/')
              }
            >
              {strings.utils.next + ' ' + String.fromCharCode('187')}
            </button>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
